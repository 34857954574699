<template>
  <div class="user-base">
    <!-- 顶部栏 -->
    <wiki-header />
    <!-- 个人栏 -->
    <div :class="[classBase + 'body']" class="user-body">
      <div :class="['wiki-content', `user-${device.orientation}`]">
        <!-- 用户信息card -->
        <wiki-user-pc-head :is_followed.sync="is_followed" :user="userInfo" />

        <!-- 用户中心 -->
        <div :class="[classBase + 'user-main']" class="user-main">
          <!-- 左边导航菜单 -->
          <div class="nav-box">
            <div class="nav-bar">
              <template>
                <router-link
                  v-for="(item, index) in navList"
                  :key="index"
                  :to="item.link + `/${requestUid}.html`"
                  replace
                >
                  <i :class="['iconfont', item.icon]"></i>
                  {{ setTitle(item) }}
                </router-link>
              </template>
              <router-link v-if="device.desktop && isSelf" replace to="/user/edit">
                <i class="iconfont icon-a-bianzu20"></i>
                我的资料
              </router-link>
            </div>
          </div>
          <!-- 右边内容 -->
          <div class="router-view">
            <!--<keep-alive>-->
            <router-view />
            <!--</keep-alive>-->
          </div>
        </div>
      </div>
    </div>
    <!-- 登录 -->
    <login v-if="isShowLogin" @onClose="onLoginClose" />
  </div>
</template>

<script>
import WikiHeader from "@/layout/wiki/components/wikiHeader";
// import WikiFooter from "@/layout/wiki/components/wikiFooter";
import { mapGetters } from "vuex";
// import ImageComp from "@/components/Image";
import WikiUserPcHead from "@/layout/wiki/components/wikiUserPcHead";
// import WikiUserWapHead from "@/layout/wiki/components/wikiUserWapHead";
import { apiUserInfo } from "@/api/userApi";
// import eventBus from "@/plugins/eventBus";
// import HorizontalNav from "./components/horizontalNav";

export default {
  name: "wikiUser",
  mixins: [],
  props: {},
  model: {},
  components: {
    // HorizontalNav,
    // WikiUserWapHead,
    WikiUserPcHead,
    // ImageComp,
    // WikiFooter,
    WikiHeader
  },
  data() {
    return {
      // 是否是自己
      isSelf: false,
      is_followed: 0,
      // 查询的用户UID
      requestUid: 0,
      isShowLogin: false,
      userInfo: {},
      navList: [
        {
          pcTitle: "我的发帖",
          otherTitle: "Ta的发帖",
          wapTitle: "发帖",
          link: "/user/index",
          icon: "icon-wodefatie",
          isPc: true
        },
        {
          pcTitle: "我的回复",
          otherTitle: "Ta的回复",
          wapTitle: "回复",
          link: "/user/comment",
          icon: "icon-wodehuifu",
          isPc: true
        },
        {
          pcTitle: "我的收藏",
          otherTitle: "Ta的收藏",
          wapTitle: "收藏",
          link: "/user/favorite",
          icon: "icon-shoucang",
          isPc: true
        },
        {
          pcTitle: "我的粉丝",
          otherTitle: "Ta的粉丝",
          wapTitle: "粉丝",
          link: "/user/follow/2",
          icon: "icon-wodefensi",
          isPc: true
        },
        {
          pcTitle: "我的关注",
          otherTitle: "Ta的关注",
          wapTitle: "关注",
          link: "/user/follow/1",
          icon: "icon-wodeguanzhu",
          isPc: true
        },
        {
          pcTitle: "我的编辑",
          otherTitle: "Ta的编辑",
          wapTitle: "编辑",
          link: "/user/version/1",
          icon: "icon-wode",
          isPc: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["loginUser", "loginUid"])
  },
  async created() {
    // this.setRequestUser();
  },
  beforeMount() {},
  mounted() {
    // 在mounted中执行，防止客户端激活失败
    this.setRequestUser();
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    setTitle(item) {
      let title = "";
      if (this.device.desktop) {
        if (this.isSelf) {
          title = item.pcTitle;
        } else {
          title = item.otherTitle;
        }
      } else {
        title = item.wapTitle;
      }
      return title;
    },
    // 获取用户信息
    getUser() {
      apiUserInfo({ uid: this.requestUid }).then(res => {
        if (res.code == 0) {
          this.userInfo = res.data.user;
          this.userInfo.is_followed = res.data.is_followed;
          this.is_followed = res.data.is_followed;
        }
      });
    },
    // 设置需要请求的用户ID
    setRequestUser() {
      let { params, path } = this.$route;
      this.requestUid = this.loginUid || 0;
      if (params && params.id) {
        this.requestUid = params.id;
      }
      // 没有登录 并且没有访问他人，重定向到首页
      if (this.requestUid == 0) {
        this.$router.repace("/");
        return;
      } else {
        if (!params || !params.id) {
          if (path == "/user/edit") {
            this.isSelf = true;
            this.userInfo = this.loginUser;
            this.$router.replace("user/edit");
          } else {
            this.$router.replace(path + `/${this.requestUid}.html`);
          }
        }
      }
      // 判断是否是本人,非本人拉取用户信息
      if (this.requestUid == this.loginUid) {
        this.isSelf = true;
        this.userInfo = this.loginUser;
      } else {
        this.getUser();
      }
    }
  },
  watch: {
    loginUser: function() {
      if (this.isSelf) {
        this.userInfo = this.loginUser;
        if (this.userInfo.uid == this.loginUid) {
          this.navList.push({
            pcTitle: "我的游戏",
            otherTitle: "Ta的游戏",
            wapTitle: "游戏",
            link: "/user/game",
            icon: "icon-youxi",
            isPc: true
          });
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.user-body {
  background-color: #f0f1f5;
  margin: 0;
  min-height: 100vh;
  padding-top: 54px;
  .wiki-content {
    padding-top: 16px;
    width: 960px;
    margin: auto;
  }
}

.user-main {
  display: flex;
  justify-content: space-between;
}

.nav-box {
  width: 170px;
}

.nav-bar {
  background: #fff;
  border-radius: 6px;
  padding: 0 20px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    border-bottom: 1px solid @border-basic;
    color: #666;
    font-size: 15px;
    transition: 0.3s;

    &:hover {
      color: #000;
    }

    .iconfont {
      font-size: 24px;
      margin-right: 12px;
      color: #d8d8d8;
    }

    &.router-link-active {
      color: #171717;

      .iconfont {
        color: #171717;
      }
    }
  }
}

.router-view {
  width: calc(100% - 186px);
  background: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
}
</style>
