import Clipboard from "clipboard";

// 默认接受十位的,然后补全十三位
export function dateFormat(timestamp) {
  if (String(timestamp).length == 10) {
    timestamp = timestamp * 1000;
  }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let time = date.getTime();
  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;
  hours = hours >= 10 ? hours : "0" + hours;
  minutes = minutes >= 10 ? minutes : "0" + minutes;
  seconds = seconds >= 10 ? seconds : "0" + seconds;
  time = time >= 10 ? time : "0" + time;
  const obj = {
    year: year,
    month: month,
    day: day,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    time: time,
    ymd: year + "-" + month + "-" + day,
    ymdhm: year + "-" + month + "-" + day + " " + hours + ":" + minutes,
    ymdhms: year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds,
    ms: minutes + ":" + seconds,
    hs: hours + ":" + minutes
  };
  return obj;
}

export function getStyleObj(ele) {
  let styleObj = {};
  let style = ele.getAttribute("style") ? ele.getAttribute("style").split(";") : [];
  style.forEach(item => {
    item = item.replace(/\s/gim, "");
    let arr = item.split(":");
    styleObj[arr[0]] = arr[1];
  });
  return styleObj;
}

/**
 * 函数防抖
 */
export function debounce(fn, delay = 200) {
  // 记录上一次的延时器
  let timer = null;
  return function() {
    let args = arguments;
    let that = this;
    // 清除上一次延时器
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
}

/**
 * 立刻执行防抖
 * @param {function} func           防抖函数
 * @param {number} wait             防抖时间间隔
 * @return {function}               返回客户调用函数
 */
function immediateDebounce(func, wait = 50) {
  let timer;
  let isRepeat = false; // 是否重复点击
  const later = () =>
    setTimeout(() => {
      isRepeat = false; // 延时wait后 isRepeat=false，timer=null，便可以调用函数
      timer = null;
    }, wait);

  return function(...params) {
    if (!timer && !isRepeat) {
      // isRepeat=false，timer=null，便可以调用函数
      func.apply(this, params);
    } else {
      isRepeat = true;
    }
    timer && clearTimeout(timer);
    timer = later();
  };
}

/**
 =* 可配置防抖函数
 * @param  {function} func        回调函数
 * @param  {number}   wait        表示时间窗口的间隔
 * @param  {boolean}  immediate   设置为ture时，是否立即调用函数
 * @return {function}             返回客户调用函数
 */
export function newDebounce(func, wait = 50, immediate = true) {
  return immediate ? immediateDebounce(func, wait) : debounce(func, wait);
}

/**
 * 函数节流
 */
export function throttle(fn, delay) {
  let timer;
  return function() {
    if (!timer) {
      fn.apply(this, arguments);
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
      }, delay);
    }
  };
}

// base64转file;
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime
  });
}

// 获取时长
export function calcDuration(duration) {
  if (!duration) return 0;
  let hour = Math.floor(duration / 3600);
  let minute = Math.floor((duration % 3600) / 60);
  let second = Math.floor(duration % 60);
  return `${hour > 0 ? `${hour < 10 ? `0${hour}` : hour}` : ""}${minute < 10 ? `0${minute}` : minute}:${
    second < 10 ? `0${second}` : second
  }`;
}

// 获取是否为主站
export function checkIsWww(host) {
  return [
    "www-s.gamekee.com",
    "www.gamekee.com",
    "www.stage.gamekee.com",
    "www.pre.gamekee.com",
    "www.local.gamekee.com"
  ].includes(host);
}

// 检查是否有wiki
export function checkIsHasWiki(alias, gameId) {
  if (!alias || alias == gameId) {
    return false;
  }
  return true;
}

/**
 * 获取跳转wiki地址
 * @param {*} item
 * @returns
 */
export function getJumpWikiUrl(alias, gameId, isHasWiki) {
  // 没有wiki的，跳游戏页
  if (!isHasWiki) {
    return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/game`;
  }
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/`;
}

/**
 * 获取跳转wiki文章详情地址
 * @param {*} alias
 * @param {*} gameId
 * @param {*} detailId
 * @returns
 */
export function getJumpWikiDetailUrl(alias, gameId, detailId, isPath = false, isNewTj) {
  if (isNewTj) {
    if (isPath) {
      return `/${alias}/tj/${detailId}.html`;
    }
    return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/tj/${detailId}.html`;
  }
  // 主站点
  if (alias === "www") {
    if (isPath) {
      return `/${detailId}.html`;
    }
    return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${detailId}.html`;
  }
  if (isPath) {
    return `/${alias}/${detailId}.html`;
  }
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/${detailId}.html`;
}

/**
 * 获取跳转wiki社区地址的
 * @param {*} alias
 * @param {*} gameId
 * @returns
 */
export function getJumpWikiListUrl(alias, gameId, isHasWiki) {
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/list`;
}

/**
 * 获取跳转wiki游戏页地址
 * @param {*} alias
 * @param {*} gameId
 * @returns
 */
export function getJumpWikiGameUrl(alias, gameId, isHasWiki) {
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/game`;
}

/**
 * 获取跳转wiki日历页
 * @param {*} alias
 * @param {*} gameId
 * @returns
 */
export function getJumpWikiDateUrl(alias, gameId) {
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/date`;
}

// 获路由地址
export function getArticleEditorRoute(alias, articleId, editorType) {
  let route = null;
  // if (editorType == 2) {
  //   route = {
  //     path: alias != "www" ? `/${alias}/illustrated-book` : `illustrated-book`,
  //     query: {
  //       id: articleId
  //     }
  //   };
  // } else if (editorType == 1 || editorType == 0) {
  //   route = {
  //     path: alias != "www" ? `/${alias}/editor` : `editor`,
  //     query: {
  //       id: articleId
  //     }
  //   };
  // }
  route = {
    path: alias != "www" ? `/${alias}/editor` : `editor`,
    query: {
      id: articleId
    }
  };
  return route;
}

/**
 * 获取游戏站点标识
 * @param {*} host
 * @returns
 */
export function getGameAlias(host) {
  host = host.split(".");
  let alias = host[0];
  return alias;
}

/**
 * 深拷贝
 * @param {*} obj
 * @param {*} clonedMap
 * @returns
 */
export function deepClone(obj, clonedMap = new WeakMap()) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // 缓存避免循环引用
  if (clonedMap.has(obj)) {
    return clonedMap.get(obj);
  }

  // 正则表达式对象
  if (obj instanceof RegExp) {
    return new RegExp(obj);
  }

  // 日期对象
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  let clone = Array.isArray(obj) ? [] : {};

  clonedMap.set(obj, clone);
  // 递归拷贝
  for (let key in obj) {
    // eslint-disable-next-line
    if (obj.hasOwnProperty(key)) {
      clone[key] = deepClone(obj[key], clonedMap);
    }
  }

  return clone;
}

/**
 * 检查是否移动端内容
 * @param {*} conetentJson
 * @returns
 */
export function checkIsImageTextContent(conetentJson = []) {
  let isImageText = true;
  for (const item of conetentJson) {
    if (item.type) {
      const typeList = ["paragraph", "image", "video"];

      if (!typeList.includes(item.type)) {
        isImageText = false;
        break;
      }

      if (item.children.length > 0) {
        isImageText = checkIsImageTextContent(item.children);
        if (!isImageText) break;
      }
    }
  }
  return isImageText;
}

/**
 * 验证身份证号码
 * @param { String } code 身份证号码
 */
export function identityIDCard(code) {
  // 身份证号前两位代表区域
  const city = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江 ",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北 ",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏 ",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外 "
  };
  const idCardReg = /^[1-9]\d{5}(19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i; // 身份证格式正则表达式
  let errorMessage = ""; // 错误提示信息
  let isPass = true; // 身份证验证是否通过（true通过、false未通过）

  // 如果身份证不满足格式正则表达式
  if (!code) {
    errorMessage = "请输入身份证号码";
    isPass = false;
  } else if (!code.match(idCardReg)) {
    errorMessage = "请输入正确的身份证号码";
    isPass = false;
  } else if (!city[code.substr(0, 2)]) {
    // 区域数组中不包含需验证的身份证前两位
    errorMessage = "请输入正确的身份证号码";
    isPass = false;
  } else if (code.length === 18) {
    // 18位身份证需要验证最后一位校验位
    code = code.split("");
    // ∑(ai×Wi)(mod 11)
    // 加权因子
    const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    // 校验位
    const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
    let sum = 0;
    let ai = 0;
    let wi = 0;
    for (let i = 0; i < 17; i++) {
      ai = parseInt(code[i]);
      wi = factor[i];
      sum += ai * wi; // 开始计算并相加
    }
    const last = parity[sum % 11]; // 求余
    if (last.toString() !== code[17]) {
      errorMessage = "请输入正确的身份证号码";
      isPass = false;
    }
  }
  return {
    errorMessage,
    isPass
  };
}

/**
 * 判断元素是否在可视范围内
 * @param {*} el
 * @returns
 */
export function isInViewPort(el, offset = 0) {
  const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const top = el.getBoundingClientRect() && el.getBoundingClientRect().top;
  return top <= screenHeight + offset;
}

/**
 * 获取视频首帧图
 * @param {string} url
 * @returns {Promise<string>}
 */
export function getFirstFrame(url) {
  return new Promise(resolve => {
    let videoDom = document.createElement("video");
    videoDom.setAttribute("crossOrigin", "Anonymous");
    videoDom.autoplay = true;
    videoDom.muted = true;
    videoDom.onloadeddata = () => {
      let h = videoDom.videoHeight;
      let w = videoDom.videoWidth;

      if (w > 500) {
        h = (500 / w) * h;
        w = 500;
      }
      let canvas = document.createElement("canvas");
      canvas.width = w;
      canvas.height = h;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(videoDom, 0, 0, w, h);
      url = canvas.toDataURL("image/png");
      resolve(url);
    };
    videoDom.src = url;
  });
}

export function toast(text) {
  const div = document.createElement("div");
  div.classList.add("__temp_toast__");
  div.innerText = text;
  document.body.append(div);
  setTimeout(() => {
    div.remove();
  }, 1500);
}

/**
 * 异步加载js
 * @param {*} url
 * @returns
 */
export function loadScript(url) {
  return new Promise((resolve, reject) => {
    // 创建 script 元素
    let script = document.createElement("script");
    script.type = "text/javascript"; // 设置类型为 JavaScript

    // 检查是否支持 onload 事件
    if (script.readyState) {
      // IE 浏览器
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null; // 清除事件
          resolve(); // 如果有回调函数，则执行
        }
      };
    } else {
      // 其他标准浏览器
      script.onload = function() {
        resolve(); // 加载完成后执行回调函数
      };
    }

    script.src = url; // 设置脚本的 URL
    document.head.appendChild(script); // 将 script 插入到 head 中
  });
}

// 生成唯一key
export function createUniqueKey(length = 8) {
  return (
    Math.random()
      .toString()
      .substring(2)
      .substring(0, length) + Date.now().toString(36)
  );
}

// 生成唯一key, 字符串的
export function createUniqueStringKey(length = 8) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let key = "";
  for (let i = 0; i < length; i++) {
    key += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return key;
}

// 复制文本
export function copyText(text) {
  return new Promise((resolve, reject) => {
    let $div = document.createElement("div");
    $div.setAttribute("data-clipboard-text", text);
    $div.classList.add("page-share-copy-btn");
    document.body.appendChild($div);
    let clipboard = new Clipboard(".page-share-copy-btn");
    clipboard.on("success", e => {
      // 复制成功
      document.body.removeChild($div);
      clipboard.destroy();
      resolve();
    });
    clipboard.on("error", e => {
      // 不支持复制
      document.body.removeChild($div);
      clipboard.destroy();
      reject("浏览器不支持自动复制,请更换其他浏览器");
    });
    $div.click();
  });
}
