// layouts
import wikiBase from "@/layout/wiki/wikiBase";
// import SimulatorBase from '../layout/wiki/simulatorBase';

// routes
// import { pcDynamicRoute, wapDynamicRoute } from '@/router/dynamicRoute';

const wikiRouter = [
  {
    path: "/:gameAlias/",
    component: wikiBase,
    meta: {
      title: "wiki",
      keepAlive: true
    },
    children: [
      // 定制主题
      {
        path: "/:gameAlias(zsca2|ba|nikke|lostsword|hw|mc|lo|snow)/",
        name: "wikiHomeNew",
        component: () => import("@/views/wiki/home/zsca/index"),
        meta: {
          title: "wiki",
          keepAlive: true,
          wikiRoute: true,
          useNewLayout: true,
          isFullLayout: true,
          hideFooter: true
        }
      },
      {
        path: "/:gameAlias(zsca2|ba|nikke|lostsword|hw|mc|lo|snow)/qz",
        name: "wikiQZNew",
        component: () => import("@/views/wiki/home/zsca/qz/index"),
        meta: {
          title: "wiki",
          keepAlive: true,
          wikiRoute: true,
          useNewLayout: true,
          isFullLayout: true
        }
      },
      {
        path: "/:gameAlias(zsca2|ba|nikke|lostsword|hw|mc|lo|snow)/kachi/:id",
        name: "wikiKCNew",
        component: () => import("@/views/wiki/home/zsca/kachi/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          useNewLayout: true,
          isFullLayout: true
        }
      },
      {
        path: "/:gameAlias(zsca2|ba|nikke|lostsword|hw|mc|lo|snow)/huodong/:id",
        name: "wikiHDNew",
        component: () => import("@/views/wiki/home/zsca/huodong/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          useNewLayout: true,
          isFullLayout: true
        }
      },
      // wiki社区
      {
        path: "/:gameAlias(zsca2|ba|nikke|lostsword|hw|mc|lo|snow)/list",
        name: "wikiListNew",
        component: () => import("@/views/wiki/list/zsIndex"),
        meta: {
          title: "社区列表",
          wikiRoute: true,
          keepAlive: true,
          useNewLayout: true,
          hideFooter: true
        }
      },
      // 详情
      {
        path: "/:gameAlias(zsca2|ba|nikke|lostsword|hw|mc|lo|snow)/:id.html",
        name: "wikiDetailNew",
        component: () => import("@/views/wiki/detail/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          useNewLayout: true
        }
      },
      // 二级页面重定向
      {
        path: "/:gameAlias(zsca2|ba|nikke|lostsword|hw|mc)/huodong/:id",
        name: "zsca_huodong",
        component: () => import("@/views/wiki/home/zsca/huodong/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          noWikiBase: true
        }
      },
      {
        path: "/:gameAlias(zsca2)/battle",
        name: "zsca_battle",
        component: () => import("@/views/wiki/battle/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          noWikiBase: true
        }
      },
      {
        path: "/:gameAlias(zsca2|ba|nikke|lostsword|hw|mc|lo|snow)/second/:id",
        name: "wikiSecondsNav",
        component: () => import("@/views/wiki/home/secondNav"),
        meta: {
          wikiRoute: true,
          keepAlive: true,
          useNewLayout: true,
          isFullLayout: true
        }
      },
      /** 抽卡模拟器 */
      {
        path: "/:gameAlias(zsca2)/quilt-card/:id",
        name: "wikiQuiltCard",
        component: () => import("@/views/wiki/home/zsca/quilt-card/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          useNewLayout: true,
          isFullLayout: true
        }
      },
      // wiki主页
      {
        path: "/:gameAlias/",
        name: "wikiHome",
        component: () => import("@/views/wiki/home/index"),
        meta: {
          wikiRoute: true,
          keepAlive: true
          // grayBody: true
        }
      },
      // 其他路由主页重定向
      {
        path: "/:gameAlias/home",
        redirect: "/:gameAlias"
      },
      {
        path: "/:gameAlias/m",
        redirect: "/:gameAlias"
      },
      {
        // 此路由仅wap端存在，PC需要重定向到首页
        path: "/:gameAlias/entry",
        redirect: "/:gameAlias"
      },
      // ...Device.desktop ? pcDynamicRoute : wapDynamicRoute,
      // 新wiki主页
      {
        path: "/:gameAlias/new",
        name: "wikiNew",
        component: () => import("@/views/wiki/new/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          keepAlive: true
        }
      },
      // wiki游戏
      {
        path: "/:gameAlias/game",
        name: "wikiGame",
        component: () => import("@/views/wiki/game/index"),
        meta: {
          wikiRoute: true,
          keepAlive: true
        }
      },
      // wiki社区
      {
        path: "/:gameAlias/list",
        name: "wikiList",
        component: () => import("@/views/wiki/list/index"),
        meta: {
          title: "社区列表",
          wikiRoute: true,
          keepAlive: true,
          hideFooter: true
        }
      },
      {
        path: "/:gameAlias/contributor",
        name: "wikiDetailContributor",
        component: () => import("@/views/wiki/detail/ContributorList"),
        meta: {
          title: "wiki",
          wikiRoute: true
        }
      },
      {
        path: "/:gameAlias/:id.html",
        name: "wikiDetail",
        component: () => import("@/views/wiki/detail"),
        meta: {
          title: "wiki",
          wikiRoute: true
        }
      },
      // 新图鉴
      {
        path: "/:gameAlias/tj/:id.html",
        name: "wikiTJ",
        component: () => import("@/views/wiki/tj/zs/index.vue"),
        meta: {
          title: "新图鉴",
          wikiRoute: true,
          keepAlive: true,
          useNewLayout: true,
          isFullLayout: true
        }
      },
      {
        path: "/:gameAlias/m/:id.html",
        redirect: "/:id.html"
      },
      {
        path: "/:gameAlias/test",
        name: "wikiTest",
        component: () => import("@/views/wiki/home/test.vue")
      }
    ]
  },
  // wap编辑器
  {
    path: "/:gameAlias/wap-editor",
    name: "wikiWapEditor",
    component: () => import("@/views/wapEditor/Index"),
    meta: {
      title: "编辑器",
      wikiRoute: true,
      keepAlive: false
    }
  },
  // 编辑器
  {
    path: "/:gameAlias/editor",
    name: "editor",
    component: () => import("@/views/editor/index"),
    meta: {
      title: "Gamekee",
      wikiRoute: true
    }
  },
  // 图鉴
  {
    path: "/:gameAlias/illustrated-book",
    name: "/illustrated-book",
    component: () => import("@/views/editor/wiki-editor/illustrated-book"),
    meta: {
      title: "图鉴",
      wikiRoute: true,
      keepAlive: true
    }
  },
  {
    path: "/:gameAlias/rich-table",
    name: "rich-table",
    component: () => import("@/views/editor/rich-table/index"),
    meta: {
      title: "新图鉴数据编辑器",
      wikiRoute: true
    }
  },
  {
    path: "/:gameAlias/rich-table/template",
    name: "rich-table-template",
    component: () => import("@/views/editor/rich-table/template"),
    meta: {
      title: "新图鉴数据模板编辑器",
      wikiRoute: true
    }
  },
  {
    path: "/:gameAlias(zsca2)/tj/map",
    name: "wikiTJMap",
    component: () => import("@/views/wiki/tj/zs/map/index.vue"),
    meta: {
      title: "新图鉴数据映射",
      wikiRoute: true
    }
  }
];

export default wikiRouter;
