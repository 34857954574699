export const wikiTheme = {
  zsca2: {
    tabBg: "rgba(182,169,151,0.48)",
    tabTextColor: "#5A4434",
    tabSelectBg: "#9C907F",
    tabSelectTextColor: "#EFEDE2",
    tabDotBg: "#b4725a",
    activeTextColor: "#44413f",
    primaryBtnBg: "#967A5F",
    primaryBtnColor: "#ffffff",
    entryBg: "rgba(222, 218, 216, 0.6)",
    entryColor: "#333333",
    entryDesColor: "#666666",
    asidePopBg: "#555a61",
    headerBg: "#1d212b",
    headerBtnBg: "#F3E8DB",
    headerBtnColor: "#5A4434",
    highlightBtnBg: "#967A5F",
    highlightBtnColor: "#ffffff"
  },
  lostsword: {
    tabBg: "rgba(182,169,151,0.48)",
    tabTextColor: "#5A4434",
    tabSelectBg: "#9C907F",
    tabSelectTextColor: "#EFEDE2",
    tabDotBg: "#b4725a",
    activeTextColor: "#44413f",
    primaryBtnBg: "#967A5F",
    primaryBtnColor: "#ffffff",
    entryBg: "rgba(222, 218, 216, 0.6)",
    entryColor: "#333333",
    entryDesColor: "#666666",
    asidePopBg: "#555a61",
    headerBg: "#1d212b",
    headerBtnBg: "#F3E8DB",
    headerBtnColor: "#5A4434",
    highlightBtnBg: "#967A5F",
    highlightBtnColor: "#ffffff"
  },
  hw: {
    tabBg: "rgba(182,169,151,0.48)",
    tabTextColor: "#5A4434",
    tabSelectBg: "#9C907F",
    tabSelectTextColor: "#EFEDE2",
    tabDotBg: "#b4725a",
    activeTextColor: "#44413f",
    primaryBtnBg: "#967A5F",
    primaryBtnColor: "#ffffff",
    entryBg: "rgba(222, 218, 216, 0.6)",
    entryColor: "#333333",
    entryDesColor: "#666666",
    asidePopBg: "#555a61",
    headerBg: "#1d212b",
    headerBtnBg: "#F3E8DB",
    headerBtnColor: "#5A4434",
    highlightBtnBg: "#967A5F",
    highlightBtnColor: "#ffffff"
  },
  mc: {
    tabBg: "rgba(182,169,151,0.48)",
    tabTextColor: "#5A4434",
    tabSelectBg: "#9C907F",
    tabSelectTextColor: "#EFEDE2",
    tabDotBg: "#b4725a",
    activeTextColor: "#44413f",
    primaryBtnBg: "#967A5F",
    primaryBtnColor: "#ffffff",
    entryBg: "rgba(222, 218, 216, 0.6)",
    entryColor: "#333333",
    entryDesColor: "#666666",
    asidePopBg: "#555a61",
    headerBg: "#1d212b",
    headerBtnBg: "#F3E8DB",
    headerBtnColor: "#5A4434",
    highlightBtnBg: "#967A5F",
    highlightBtnColor: "#ffffff"
  },
  ba: {
    tabBg: "#98DCFF",
    tabTextColor: "#3D4667",
    tabSelectBg: "#D2F0FF",
    tabSelectTextColor: "#5C7B9C",
    tabDotBg: "#FFDA35",
    activeTextColor: "#4DC3FF",
    primaryBtnBg: "#D7EBFF",
    primaryBtnColor: "#4F667D",
    entryBg: "#F3F8FB",
    entryColor: "#4F527D",
    entryDesColor: "#A0A0A0",
    asidePopBg: "linear-gradient( 180deg, #53C4FF 0%, #A4E5FF 100%)",
    headerBg: "#64CBFF",
    headerBtnBg: "#D7EBFF",
    headerBtnColor: "#4F667D",
    highlightBtnBg: "#4DC3FF",
    highlightBtnColor: "#ffffff"
  },
  nikke: {
    tabBg: "#98DCFF",
    tabTextColor: "#3D4667",
    tabSelectBg: "#D2F0FF",
    tabSelectTextColor: "#5C7B9C",
    tabDotBg: "#FFDA35",
    activeTextColor: "#4DC3FF",
    primaryBtnBg: "#D7EBFF",
    primaryBtnColor: "#4F667D",
    entryBg: "#F3F8FB",
    entryColor: "#4F527D",
    entryDesColor: "#A0A0A0",
    asidePopBg: "linear-gradient( 180deg, #53C4FF 0%, #A4E5FF 100%)",
    headerBg: "#64CBFF",
    headerBtnBg: "#D7EBFF",
    headerBtnColor: "#4F667D",
    highlightBtnBg: "#4DC3FF",
    highlightBtnColor: "#ffffff"
  },
  lo: {
    tabBg: "#98DCFF",
    tabTextColor: "#3D4667",
    tabSelectBg: "#D2F0FF",
    tabSelectTextColor: "#5C7B9C",
    tabDotBg: "#FFDA35",
    activeTextColor: "#4DC3FF",
    primaryBtnBg: "#D7EBFF",
    primaryBtnColor: "#4F667D",
    entryBg: "#F3F8FB",
    entryColor: "#4F527D",
    entryDesColor: "#A0A0A0",
    asidePopBg: "linear-gradient( 180deg, #53C4FF 0%, #A4E5FF 100%)",
    headerBg: "#64CBFF",
    headerBtnBg: "#D7EBFF",
    headerBtnColor: "#4F667D",
    highlightBtnBg: "#4DC3FF",
    highlightBtnColor: "#ffffff"
  },
  snow: {
    tabBg: "#98DCFF",
    tabTextColor: "#3D4667",
    tabSelectBg: "#D2F0FF",
    tabSelectTextColor: "#5C7B9C",
    tabDotBg: "#FFDA35",
    activeTextColor: "#4DC3FF",
    primaryBtnBg: "#D7EBFF",
    primaryBtnColor: "#4F667D",
    entryBg: "#F3F8FB",
    entryColor: "#4F527D",
    entryDesColor: "#A0A0A0",
    asidePopBg: "linear-gradient( 180deg, #53C4FF 0%, #A4E5FF 100%)",
    headerBg: "#64CBFF",
    headerBtnBg: "#D7EBFF",
    headerBtnColor: "#4F667D",
    highlightBtnBg: "#4DC3FF",
    highlightBtnColor: "#ffffff"
  }
};
