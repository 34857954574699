import service from "@/plugins/request";

// 获取小程序码
export function apiGetWechatMiniAppQrcode(params) {
  return service({
    url: "/v1/wechat/getWxaCodeUnlimit",
    method: "post",
    data: params,
    responseType: "arraybuffer"
  });
}

// 获取服务号二维码
export function apiGetWechatServiceQrcode(params) {
  return service({
    url: "/v1/wechat/queryWechatServiceQrCode",
    method: "post",
    data: params
  });
}
