<template>
  <div
    :class="['home-header', hasScroll ? 'scroll-bg' : '']"
    :style="hasScroll || showHeader ? (theme ? { backgroundColor: theme.headerBg } : {}) : {}"
  >
    <div class="wrapper">
      <div class="left">
        <a href="/">
          <img class="logo" src="@/assets/images/logo1.png" alt="" />
        </a>
        <router-link :to="$route.name == 'wikiHomeNew' ? `/${alias}/game` : `/${alias}/`" v-if="gameInfo.Config">
          <img class="game-slogn" :src="gameInfo.Config.slogan" alt="" />
        </router-link>
      </div>
      <div class="right">
        <div class="header-btns" v-if="scrollTop > 120 && $route.name == 'wikiHomeNew'">
          <button
            class="btn"
            @click="goEdit"
            :style="
              theme
                ? {
                    backgroundColor: theme.headerBtnBg,
                    color: theme.headerBtnColor
                  }
                : {}
            "
          >
            <!-- <img class="icon" src="@/assets/zs_imgs/edit-icon.png" alt="" /> -->
            <i class="icon zsicon icon-bianji" alt=""></i>
            <span>投稿/发帖</span>
          </button>
          <button
            class="btn"
            @click="openQuestion"
            :style="
              theme
                ? {
                    backgroundColor: theme.headerBtnBg,
                    color: theme.headerBtnColor
                  }
                : {}
            "
          >
            <!-- <img class="icon" src="@/assets/zs_imgs/question-icon.png" alt="" /> -->
            <i class="icon zsicon icon-tiwen" alt=""></i>
            <span>
              萌新提问
            </span>
          </button>
        </div>
        <!-- 搜索 -->
        <div class="search-box">
          <input v-model="kw" type="text" placeholder="搜你所想" @keyup.enter="onSearch" />
          <i class="icon zsicon icon-sousuo" alt="" @click="onSearch"></i>
          <!-- <img class="icon" src="@/assets/zs_imgs/wiki-icon-search.png" alt="" @click="onSearch" /> -->
        </div>
        <!-- wiki 内容导航 -->
        <div class="wiki-nav" v-if="$route.name == 'wikiHomeNew'">
          <el-dropdown trigger="hover" @command="jumpNav" placement="bottom">
            <div class="nav-box">
              <!-- <img class="nav-icon" src="@/assets/zs_imgs/wiki-icon-nav.png" alt="" /> -->
              <i class="zsicon icon-daohang"></i>
              <span>导航</span>
            </div>
            <el-dropdown-menu slot="dropdown" class="nav-menu" v-if="entryList.length > 1">
              <el-dropdown-item v-for="item in entryList" :key="item.id" :command="item.id">{{
                item.name
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 用户信息 -->
        <div class="user" v-if="loginUid">
          <img class="avatar" :src="loginUser.avatar" alt="" />
          <!-- 消息数量 -->
          <span class="red-point" v-if="redPoint.total > 0">{{ redPoint.total > 99 ? "99+" : redPoint.total }}</span>
          <!-- 弹出框 -->
          <div class="user-box">
            <div class="avatar">
              <img :src="loginUser.avatar" alt="" />
            </div>
            <div class="username">{{ loginUser.username }}</div>
            <div class="btns">
              <router-link
                class="btn"
                :to="`/user/index/${loginUser.uid}.html`"
                :style="
                  theme
                    ? {
                        backgroundColor: theme.primaryBtnBg,
                        color: theme.primaryBtnColor
                      }
                    : {}
                "
                >个人中心</router-link
              >
              <el-popover
                popper-class="wxacode-popover"
                :visible-arrow="false"
                trigger="hover"
                placement="bottom-start"
                width="218"
                v-model="showWxacodePopover"
              >
                <!-- <a class="msg-btn" href="/msg/1" slot="reference">
                  消息<span v-if="redPoint.total > 0">{{ redPoint.total > 99 ? "99+" : redPoint.total }}</span>
                </a> -->
                <template slot="reference">
                  <router-link
                    class="btn"
                    to="/msg/1"
                    :style="
                      theme
                        ? {
                            backgroundColor: theme.primaryBtnBg,
                            color: theme.primaryBtnColor
                          }
                        : {}
                    "
                  >
                    我的消息
                    <span class="red-point" v-if="redPoint.total > 0">{{
                      redPoint.total > 99 ? "99+" : redPoint.total
                    }}</span>
                  </router-link>
                </template>
                <div class="container">
                  <div class="title">
                    <div>扫码关注</div>
                    <div>GameKee服务号</div>
                  </div>
                  <div class="tips">
                    <div>互动消息实时推送</div>
                  </div>
                  <div class="qrcode">
                    <img :src="getServiceQrcodeSrc()" alt="服务号二维码" />
                  </div>
                </div>
              </el-popover>
            </div>
            <div class="logout" @click="loginOut">退出登录</div>
          </div>
        </div>
        <!-- 未登录 -->
        <div class="user" v-else>
          <img class="not-login" src="@/assets/zs_imgs/wiki-icon-user.png" alt="" @click="onShowLogin" />
        </div>
      </div>
    </div>
    <!-- 提问 -->
    <QuestionAdd ref="questionAdd" customClass="dark"></QuestionAdd>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// plugins
import eventBus from "@/plugins/eventBus";
import QuestionAdd from "../../components/questionAdd";

export default {
  inject: ["getWxaQrcode", "getServiceQrcodeSrc"],
  props: {
    entryList: {
      type: Array,
      default: () => []
    },
    isScroll: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  components: {
    QuestionAdd
  },
  data() {
    return {
      kw: "",
      hasScroll: false,
      showWxacodePopover: false,
      scrollTop: 0
    };
  },
  computed: {
    ...mapGetters(["gameInfo", "loginUser", "loginUid", "redPoint"])
  },
  mounted() {
    if (this.isScroll) {
      window.addEventListener("scroll", this.handleScroll);
    }

    const { anchor } = this.$route.query;
    if (anchor) {
      setTimeout(() => {
        this.jumpNav(anchor);
      });
    }
  },
  beforeDestroy() {
    if (this.isScroll) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    ...mapActions(["loginOut"]),
    // 导航跳转
    jumpNav(id) {
      // this.$router.push(`/${this.gameInfo.alias}/seconds/${id}`);
      let top = document.getElementById("menu-" + id).offsetTop;
      window.scrollTo({
        top: top
        // behavior: "smooth"
      });
    },
    // 打开登录
    onShowLogin() {
      eventBus.$emit("onShowLogin");
    },
    // 搜索
    onSearch() {
      this.$router.push(`/${this.gameInfo.alias}/list?isSearch=1&kw=${this.kw || ""}`);
    },
    // 打开提问
    openQuestion() {
      this.$refs.questionAdd.open();
    },
    // 打开编辑
    goEdit() {
      if (!this.isLoginShow("")) {
        sessionStorage.setItem("login_jump_path", `/${this.gameInfo.alias}/editor`);
        return false;
      }
      if (!this.isAuthRealName()) {
        sessionStorage.setItem("login_jump_path", `/${this.gameInfo.alias}/editor`);
        return false;
      }
      this.$router.push(`/${this.gameInfo.alias}/editor`);
    },
    handleScroll() {
      const winScrollTop = window.document.documentElement.scrollTop || window.document.body.scrollTop;
      // const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      // const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      this.scrollTop = winScrollTop;

      if (winScrollTop > 0) {
        this.hasScroll = true;
      } else {
        this.hasScroll = false;
      }

      // if (winScrollTop >= scrollHeight - windowHeight - 156) {
      //   // console.log("滚动到底部", scrollHeight - windowHeight - scrollTop);
      //   this.bottom = 156 - (scrollHeight - (winScrollTop + windowHeight));
      // }
    }
  }
};
</script>

<style lang="less" scoped>
.nav-menu {
  background: var(--zs-nav-menu-bg) !important;
  border-radius: 8px !important;
  border: 1px solid var(--zs-nav-menu-border-color) !important;
  padding: 14px 10px 10px;
  /deep/ .el-dropdown-menu__item {
    font-size: 13px;
    font-weight: 400;
    background: transparent !important;
    height: 28px;
    min-width: 92px;
    margin-bottom: 4px;
    padding: 0 9px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: var(--zs-nav-menu-text-color);
    line-height: 28px;
    &:focus,
    &:hover {
      color: var(--zs-nav-menu-text-hover-color);
      background: var(--zs-nav-menu-text-hover-bg) !important;
    }
  }
  /deep/ .popper__arrow {
    display: none !important;
  }
}
.home-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 60px;
  &.scroll-bg {
    background: var(--zs-header-bg);
  }

  .wrapper {
    width: 1216px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      height: 100%;
      display: flex;
      align-items: center;
      .logo {
        height: 20px;
        margin-right: 22px;
      }

      .game-slogn {
        height: 30px;
      }
    }
    .right {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 30px;
      .header-btns {
        display: flex;
        align-items: center;
        gap: 30px;
        // border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        .btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 128px;
          height: 32px;
          border-radius: 16px;
          background: var(--zs-header-btn-bg);
          font-size: 14px;
          color: var(--zs-header-btn-text-color);
          border: none;
          outline: none;
          &:hover {
            opacity: 0.9;
          }
          .icon {
            font-size: 18px;
            margin-right: 3px;
          }
        }
      }
      .search-box {
        position: relative;
        width: 261px;
        height: 32px;
        border-radius: 21px;
        background: var(--zs-input-bg);
        padding-right: 44px;
        input {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          outline: none;
          padding: 0 0 0 18px;
          font-weight: 400;
          font-size: 14px;
          color: var(--zs-input-text-color);
          line-height: 21px;
          &::placeholder {
            color: var(--zs-input-text-color);
          }
        }

        .icon {
          position: absolute;
          right: 17px;
          top: 6px;
          font-size: 20px;
          cursor: pointer;
          color: var(--zs-input-text-color);
          &:hover {
            opacity: 0.9;
          }
        }
      }
      .wiki-nav {
        .nav-box {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          color: var(--zs-nav-text-color);
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
          i {
            margin-right: 6px;
            font-size: 24px;
          }
        }
      }

      .user {
        position: relative;
        &:hover {
          .user-box {
            display: block;
            transform: translateX(-50%) scale(1);
          }
        }
        .red-point {
          position: absolute;
          right: -6px;
          top: -7px;
          display: inline-block;
          padding: 0 5px;
          word-break: keep-all;
          background: #cf4747;
          border-radius: 7px;
          border: 1px solid #ffffff;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 14px;
        }
        > .not-login {
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
        > .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 2px solid #fff;
          cursor: pointer;
        }
        .user-box {
          display: none;
          position: absolute;
          top: 25px;
          left: 16px;
          transform: translateX(-50%) scale(0.8);
          width: 268px;
          min-height: 210px;
          z-index: 1000;
          background: var(--zs-user-box-bg);
          border-radius: 15px;
          padding-top: 69px;
          transition: all 1s;
          .avatar {
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: var(--zs-user-box-avatar-bg);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              border: 4px solid #ffffff;
            }
          }

          .username {
            width: 100%;
            font-weight: bold;
            font-size: 16px;
            color: var(--zs-user-box-name-color);
            line-height: 24px;
            text-align: center;
            margin-bottom: 23px;
          }

          .btns {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            .btn {
              position: relative;
              display: inline-block;
              width: 98px;
              height: 32px;
              background: var(--zs-user-box-btn-bg);
              border-radius: 21px;
              font-weight: 500;
              font-size: 14px;
              color: var(--zs-user-box-btn-color);
              line-height: 32px;
              text-align: center;
              &:hover {
                opacity: 0.9;
              }
            }
          }

          .logout {
            width: 100%;
            margin-top: 23px;
            padding: 18px 20px;
            font-weight: 400;
            font-size: 14px;
            color: var(--zs-user-box-logout-text-color);
            line-height: 21px;
            text-align: center;
            border-top: 1px solid var(--zs-user-box-logout-border-color);
            cursor: pointer;
            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }
  }
}
/deep/ .wxacode-popover {
  width: 188px !important;
  height: 264px !important;
  border-radius: 12px !important;
  padding: 0 !important;
  border: none !important;
  background: url("../../../../../assets/images/miniapp-msg-tips-bg.png") no-repeat !important;
  background-size: 188px 264px !important;
  box-shadow: none !important;
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 65px 20px 18px;
    .title {
      width: 100%;
      padding-right: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 17px;
      font-weight: 900;
      color: #435f6a;
      text-align: right;
      line-height: 17px;
      margin-bottom: 12px;
    }
    .tips {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      margin-bottom: 10px;
      &::before,
      &::after {
        content: "";
        display: block;
        width: 20px;
        height: 1px;
        background: #dfdfdf;
      }
    }
    .qrcode {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
.my-msg {
  display: inline-block;
  position: relative;
  width: 98px;
  height: 32px;
  background: #d4d1cd;
  border-radius: 21px;
  font-weight: 500;
  font-size: 14px;
  color: #2a3035;
  line-height: 32px;
  text-align: center;
  &:hover {
    opacity: 0.9;
  }
  .red-point {
    position: absolute;
    right: -6px;
    top: -7px;
    display: inline-block;
    padding: 0 5px;
    word-break: keep-all;
    background: #cf4747;
    border-radius: 7px;
    border: 1px solid #ffffff;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 14px;
  }
}
</style>
