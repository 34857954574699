<template>
  <el-container class="manage-page" v-if="loginUid && roleInfo.length">
    <admin-header />
    <el-main>
      <el-container class="manage-container">
        <el-aside class="left-aside">
          <el-scrollbar class="scrollbar-wrapper">
            <menu-container2 />
          </el-scrollbar>
        </el-aside>
        <el-scrollbar ref="rightContainer" class="scrollBar">
          <el-main class="right-container">
            <breadcrumb />
            <el-card shadow="never">
              <router-view />
            </el-card>
          </el-main>
        </el-scrollbar>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import adminHeader from "./components/adminHeader";
import breadcrumb from "./components/breadcrumb";
import eventBus from "@/plugins/eventBus";
import MenuContainer2 from "./components/menuContainer2";
import { mapGetters } from "vuex";

export default {
  name: "adminBase",
  mixins: [],
  props: {},
  model: {},
  components: {
    MenuContainer2,
    adminHeader,
    breadcrumb
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["roleInfo", "loginUid"])
  },
  watch: {
    roleInfo(val) {
      this.checkRoutePermission(val);
    }
  },
  created() {},
  beforeMount() {
    if (!this.loginUid) {
      this.$router.replace("/404");
    }
  },
  mounted() {
    eventBus.$on("onSlideChange", this.onSlideChange);
  },
  methods: {
    checkRoutePermission(roleInfo = []) {
      const route = this.$route;
      let permission = route.meta ? route.meta.permission : "";
      if (!roleInfo.includes(permission)) {
        this.$router.replace("/404");
      }
    },
    onSlideChange(item) {
      let { rightContainer } = this.$refs;
      rightContainer.scrollTop = 0;
    }
  }
};
</script>

<style lang="less" scoped>
.manage-page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  > .el-main {
    padding: 0;
    height: auto;
    flex-basis: 0;
  }
}

.manage-container {
  width: 1200px;
  height: 100%;
  margin: auto;
  padding: 0;
}

.scrollBar {
  height: 100%;
  flex: 1;

  /deep/ .el-scrollbar__wrap {
    overflow-x: auto;
  }
}

.right-container {
  padding-top: 0;
  position: relative;
  z-index: 0;
}

.left-aside {
  width: 250px;
  height: 100%;
  background: #fff;
  border-right: 1px solid @border-basic;
  position: relative;
  z-index: 1;

  /deep/ .scrollbar-wrapper {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: auto;
    }
  }
}
</style>
