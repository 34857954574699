<template>
  <div class="emoji-panel">
    <el-tabs v-model="activeName" type="card" tab-position="bottom" @tab-click="handleClick">
      <el-tab-pane v-for="group in emojiList" :key="group.id" :name="group.id + ''">
        <img slot="label" class="tab-label" :src="group.icon" :alt="group.name" />
        <el-scrollbar style="height: 290px;" wrapStyle="overflow-x: hidden;">
          <div class="emoji-grid" @click="handleClick(group, $event)">
            <img v-for="e in group.children" :key="e.id" :src="e.icon" :alt="e.name" :data-emoji="e.name" />
          </div>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { apiEmojiGroupQueryList } from "@/api/gameApi";

export default {
  name: "EmojiPanel",
  data() {
    return {
      activeName: "",
      emojiList: []
    };
  },
  beforeMount() {
    this.emojiList = window.app.$store.state.emojiData;
    if (this.emojiList.length > 0) {
      this.activeName = this.emojiList[0].id + "";
    }
  },
  methods: {
    async fetchEmojiList() {
      const { code, data } = await apiEmojiGroupQueryList();
      if (code === 0) {
        this.activeName = data[0].id + "";
        this.emojiList = Object.freeze(data);
      }
    },
    /**
     * @param {PointerEvent} event
     */
    handleClick(group, event) {
      if (event.target.tagName !== "IMG") return;
      const { emoji } = event.target.dataset;
      if (!emoji) return;
      this.$emit("click", { icon: event.target.src, name: emoji });
    }
  }
};
</script>
<style lang="less" scoped>
.emoji-panel {
  width: 360px;
  height: 325px;
  user-select: none;
  /deep/ .el-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex-grow: 1;
    }
    .el-tabs__header {
      border-bottom-width: 0;
      .is-bottom {
        margin-top: 0;
      }
      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        line-height: 36px;
      }
      .el-tabs__nav-prev {
        left: 4px;
      }
      .el-tabs__nav-next {
        right: 4px;
      }
    }
    .el-tabs__nav-scroll {
      background-color: #f2f2f2;
      .el-tabs__nav {
        border: none;
        .is-active {
          background-color: #fff;
        }
      }
      .el-tabs__item {
        height: 36px;
        line-height: initial;
        display: inline-flex;
        align-items: center;
        padding: 0 16px;
        border: none;
      }
    }
  }
}
.emoji-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  padding: 17px 0;
  row-gap: 17px;
  img {
    width: 56px;
    height: 56px;
  }
}
.tab-label {
  width: 22px;
  height: 22px;
}
</style>
