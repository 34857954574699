import { render, staticRenderFns } from "./homeHeader.vue?vue&type=template&id=bed4d470&scoped=true"
import script from "./homeHeader.vue?vue&type=script&lang=js"
export * from "./homeHeader.vue?vue&type=script&lang=js"
import style0 from "./homeHeader.vue?vue&type=style&index=0&id=bed4d470&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-load_31f638085dcfa56dedac9f85bae04116/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed4d470",
  null
  
)

export default component.exports