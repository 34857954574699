<template>
  <el-dialog
    title=""
    append-to-body
    :visible.sync="visible"
    width="303px"
    :before-close="close"
    @opend="onOpend"
    @closed="onClosed"
  >
    <div class="container">
      <div class="top-icon">
        <img src="@/assets/images/sightseer-tips-icon.png" alt="" />
      </div>
      <div class="tips">
        <span>您当前尚未创建账号</span> <span>刚刚提交的{{ type === "comment" ? "评论" : "提问" }}未成功发布</span>
      </div>
      <div style="text-align: center;">
        <el-button class="login-btn" @click="handleLogin">前往登录/注册</el-button>
      </div>
      <div class="tips-desc">
        您发布的{{ type === "comment" ? "评论" : "提问" }}已经被版主检测到，优质{{
          type === "comment" ? "评论" : "提问"
        }}可能 会被我们精选至{{ type === "comment" ? "评论" : "社" }}区。当前您可以体验部分核
        心功能，但无法解锁全部高级功能，且您发布的
        {{ type === "comment" ? "评论" : "提问" }}存在丢失风险。为了保障您的信息案前，并
        获得更好的体验与服务，您可以注册或登录您的 账号。
      </div>
    </div>
  </el-dialog>
</template>

<script>
import eventBus from "@/plugins/eventBus";
export default {
  data() {
    return {
      visible: false,
      list: [],
      type: "comment",
      /** 通过点击登录导致关闭的还是点击关闭按钮或遮罩导致关闭的 */
      closeType: ""
    };
  },
  methods: {
    /**
     *
     * @param {"comment" | "question"} type - 评论/提问
     */
    open(type) {
      this.type = type || "comment";
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    async handleLogin() {
      this.closeType = "login";
      await this.$nextTick();
      eventBus.$emit("onShowLogin", this.type === "question" ? "questionLogin" : undefined);
      this.close();
    },
    onOpend() {
      this.closeType = "";
    },
    onClosed() {
      this.$emit("closed", this.closeType);
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: transparent;
}
/deep/ .el-dialog__header {
  padding: 0;
  display: none;
  .el-dialog__headerbtn {
    top: 6px;
    right: 6px;
  }
}
/deep/ .el-dialog__body {
  padding: 0;
}

.container {
  width: 303px;
  height: 318px;
  background: #ffffff;
  border-radius: 10px;
  border: 4px solid #2fceff;
  padding-top: 76px;
  .top-icon {
    height: 160px;
    height: 108px;
    position: absolute;
    top: -43px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tips {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .login-btn {
    width: 263px;
    height: 36px;
    background: #2fceff;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    margin: 0 auto;
    border: none;
    box-shadow: none;
  }

  .tips-desc {
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    padding: 24px 20px;
  }
}
</style>
