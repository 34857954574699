<template>
  <el-dialog
    title="提问"
    append-to-body
    :visible.sync="visible"
    width="550px"
    :before-close="close"
    :custom-class="customClass"
    top="5vh"
  >
    <div class="container">
      <div>
        <SendQuestionBox
          ref="sendBox"
          :on-ok="onAddQuestion"
          placeholder="写下你的问题，准确地描述问题更容易得到解答"
          send-btn-text="提问"
          @focus="handleFocusBox"
          :show-video="false"
          :inputAutoFocus="true"
        ></SendQuestionBox>
      </div>
      <div class="title" @click="openMore">大家都在问<img src="@/assets/images/ques-arrow-right.png" alt="" /></div>
      <div class="ques-list" v-if="list.length">
        <a
          v-for="item in list"
          :key="item.id"
          :href="getJumpWikiDetailUrl(item.game_alias || item.game.alias, item.game_id || item.game.id, item.id)"
          target="_blank"
        >
          <div class="item">
            <div class="item-title">{{ item.title }}</div>
            <div class="bottom">
              <div class="info">
                <span v-if="item.comment_count">{{ formatCount(item.comment_count) }}回复 · </span>
                <span>{{ item.comment_at ? formatNow(item.comment_at) : formatNow(item.updated_at) }}</span>
              </div>
              <div class="user">
                <span>{{ item.user.username }}</span>
                <img class="avatar" :src="item.user.avatar" alt="" />
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="empty-box" v-else>~暂无提问~</div>
    </div>
    <SightseerCommentTips ref="sightseerCommentTips" @closed="onTipClosed" />
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { Message } from "element-ui";
import dayjs from "dayjs";
// api
import { apiContentTopList, apiContentEdit } from "@/api/contentApi";
// components
import SendQuestionBox from "./sendQuestionBox.vue";
import SightseerCommentTips from "../../components/sightseerCommentTips";
// plugins
import { getJumpWikiDetailUrl, toast } from "@/plugins/util";
import eventBus from "@/plugins/eventBus";

export default {
  inject: ["getWxaQrcode", "getServiceQrcodeSrc"],
  components: { SendQuestionBox, SightseerCommentTips },
  props: ["customClass"],
  data() {
    return {
      visible: false,
      list: []
    };
  },
  computed: {
    ...mapGetters(["gameInfo"])
  },
  methods: {
    getJumpWikiDetailUrl,
    open() {
      this.visible = true;
      this.getList();
    },
    close() {
      this.visible = false;
    },
    getList() {
      const params = {
        page_no: 1,
        limit: 5,
        type: 17, // 提问帖子
        order_by: "created_at"
      };
      apiContentTopList(params).then(res => {
        console.log(res);
        if (res && res.code === 0) {
          this.list = res.data;
        }
      });
    },
    formatCount(count) {
      if (count >= 1000 && count < 10000) {
        return Math.floor(count / 1000) + "k+";
      } else if (count > 10000) {
        return Math.floor(count / 10000) + "w+";
      }
      return count;
    },
    formatNow(time) {
      const date = dayjs(time * 1000);
      const now = dayjs();
      const seconds = now.diff(date, "second");
      const minute = now.diff(date, "minute");
      const hours = now.diff(date, "hour");
      const day = now.diff(date, "day");
      if (seconds < 60) {
        return "刚刚";
      }
      if (minute < 60) {
        return minute + "分钟前";
      }
      if (hours < 24) {
        return hours + "小时前";
      }
      if (day < 7) {
        return day + "天前";
      }
      return date.format("YYYY-MM-DD HH:mm");
    },
    onAddQuestion(data) {
      const { text = "", thumb, contentJson } = data;
      let params = {
        editor_type: 1, // 新编辑器
        content_json: JSON.stringify(contentJson),
        summary: text.slice(0, 100),
        title: text.length > 50 ? text.slice(0, 50) + "..." : text,
        type: 17, // 提问
        thumb: thumb.join(",")
      };

      if (!this.isLoginShow("", "questionLogin")) {
        let openLoginCount = 0;
        eventBus.$on("onQuestionLoginClose", () => {
          // 弹登录 => 弹提示 => 弹登录
          openLoginCount++;
          if (openLoginCount === 2) {
            this.$emit("sightseerQuestion", params);
            localStorage.removeItem("__question_store__");
            eventBus.$off("onQuestionLoginClose");
            return;
          }
          this.$refs.sightseerCommentTips.open("question");
          // 5s后自动关闭并发布提问，如果在5s内用户手动关闭提示框（methods.onTipClosed），则需要清除此定时器，否则游客提问会发送两次
          this.autoPublishQuestionTimer = setTimeout(() => {
            this.$emit("sightseerQuestion", params);
            localStorage.removeItem("__question_store__");
            eventBus.$off("onQuestionLoginClose");
            this.$refs.sightseerCommentTips.close();
          }, 5000);
        });
        localStorage.setItem("__question_store__", JSON.stringify(params));
        return;
      }
      if (!this.isAuthRealName("实名认证后才能提问")) return false;

      apiContentEdit(params).then(res => {
        if (res && res.code === 0) {
          sessionStorage.removeItem("question_content", JSON.stringify(params));
          // 清空数据
          this.$refs.sendBox.onClear();
          // 获取问题列表
          this.getList();
          if (this.loginUid && this.loginUser.is_bind_min_app) {
            toast("发布成功");
          } else {
            // 提示可前往小程序
            this.$message({
              title: "",
              customClass: "publish-miniapp-tips",
              duration: 0,
              showClose: true,
              message: (
                <div class="miniapp-tips-content">
                  <div class="title">
                    <img src={require("@/assets/images/ok-icon.png")} />
                    恭喜您 发布成功
                  </div>
                  <div class="content">
                    <div class="qrcode">
                      <img src={this.getServiceQrcodeSrc()} alt="服务号二维码" />
                    </div>
                    <div class="tips">
                      <div class="title">扫码关注GameKee服务号</div>
                      <div class="desc">随时接收最新消息</div>
                      <div class="tags">
                        <span>评论回复</span>
                        <span>点赞收藏</span>
                        <span>攻略讨论</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            });
            // xxs后关闭
            const duration = this.loginUid && this.loginUser.is_bind_min_app ? 3 * 1000 : 10 * 1000;
            setTimeout(() => {
              Message.closeAll();
            }, duration);
          }
        }
      });
    },
    /**
     * 未登录发布提问提示框关闭事件，关闭可由点击遮罩或关闭按钮或取消按钮触发，也可由点击登录按钮触发
     * 如果已经提示用户“您当前尚未创建账号”，仍然选择关闭弹窗，则将提问以游客提问形式发布
     * @param {string} value
     */
    onTipClosed(value) {
      if (this.autoPublishQuestionTimer) {
        clearTimeout(this.autoPublishQuestionTimer);
      }

      if (value !== "login") {
        eventBus.$off("onQuestionLoginClose");
        let cacheQuestionData = localStorage.getItem("__question_store__");
        if (cacheQuestionData) {
          cacheQuestionData = JSON.parse(cacheQuestionData);
          this.$emit("sightseerQuestion", cacheQuestionData);
          localStorage.removeItem("__question_store__");
        }
      }
    },
    openMore() {
      this.close();
      this.$router.push(`/${this.gameInfo.alias}/list?tab=17`);
    },
    handleFocusBox() {}
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  padding: 20px 20px 0 20px;
  .el-dialog__headerbtn {
    top: 16px;
    right: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 22px;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 19px;
  margin-bottom: 20px;
  cursor: pointer;
  img {
    width: 13px;
    height: 13px;
    margin-left: 6px;
  }
}
.ques-list {
  width: 100%;
  .item {
    width: 100%;
    background: #f8f8f8;
    border-radius: 10px 10px 10px 10px;
    padding: 10px;
    margin-bottom: 10px;
    .item-title {
      font-size: 14px;
      color: #333;
      line-height: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f1f1f1;
    }
    .bottom {
      padding-top: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info {
        font-size: 12px;
        color: #999;
        line-height: 14px;
      }
      .user {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666;
        line-height: 14px;
        .avatar {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-left: 6px;
        }
      }
    }
  }
}
.empty-box {
  width: 100%;
  height: calc(100% - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
</style>
<style lang="less" scoped>
/deep/ .dark {
  background: linear-gradient(to bottom, #2d3344, #343c45);
  .el-dialog__header {
    padding: 20px 20px 0 20px;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 16px;
      right: 20px;
      &:hover .el-dialog__close {
        color: #fff;
      }
    }
  }
  .el-dialog__close {
    color: #ffffff;
  }
  .article-edit-box {
    background-color: #20242f;
    color: #bebebe;
  }
  .title {
    color: #fff;
    padding-top: 20px;
    border-top: 1px solid rgba(233, 233, 233, 0.12);
  }
  .ques-list .item {
    background-color: transparent;
    border-bottom: 1px solid rgba(233, 233, 233, 0.04);
    border-radius: 0;
    .item-title {
      color: #bebebe;
      border-bottom: none;
    }
    .bottom .user {
      color: #999999;
    }
  }
}
</style>
