import service from "@/plugins/request";

// 词条列表
export function apiWikiEntry(params) {
  return service({
    url: "/v1/wiki/entry",
    method: "get",
    params: params
  });
}
// wiki首页
export function apiWikiIndex(params) {
  return service({
    url: "/v1/wiki/index",
    method: "get",
    params: params
  });
}
// wiki首页
export function apiWikiIndexV2(params) {
  return service({
    url: "/v1/wiki/indexV2",
    method: "get",
    params: params
  });
}

// wiki管理员
export function apiWikiAdminList(params) {
  return service({
    url: "/v1/wiki/entry/adminList",
    method: "get",
    params: params
  });
}

// wiki最近更新的词条
export function apiWikiEntryLastUpdateList(params) {
  return service({
    url: "/v1/wiki/entry/updateList",
    method: "get",
    params: params
  });
}
