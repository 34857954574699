import adminWwwBase from "../layout/admin/adminWwwBase";
import emptyLayout from "../layout/admin/emptyLayout";

const adminWwwRouter = [
  {
    path: "/admin_www",
    name: "admin_www",
    component: adminWwwBase,
    redirect: "/admin_www/detail",
    meta: {
      title: "后台管理"
    },
    children: [
      {
        path: "/admin_www/detail",
        name: "admin_www_detail",
        component: () => import("./../adminViews/www/detailManage"),
        meta: {
          title: "首页管理",
          permission: "www_detail_edit"
        }
      },
      {
        path: "/admin_www/module",
        name: "admin_www_module",
        component: emptyLayout,
        redirect: "/admin_www/module/banner",
        meta: {
          title: "模块管理"
        },
        children: [
          // {
          //   path: "/admin_www/module/module_edit",
          //   name: "admin_www_module_edit",
          //   component: () => import("./../adminViews/www/moduleEdit"),
          //   meta: {
          //     title: "模块编辑",
          //     permission: "www_module_edit"
          //   }
          // },
          {
            path: "/admin_www/module/game_recommend",
            name: "admin_www_game_recommend",
            component: () => import("./../adminViews/www/gameRecommend.vue"),
            meta: {
              title: "游戏推荐",
              permission: "www_banner_edit"
            }
          },
          {
            path: "/admin_www/module/banner",
            name: "admin_www_banner",
            component: () => import("../adminViews/www/bannerManage"),
            meta: {
              title: "banner管理",
              permission: "www_banner_edit"
            }
          },
          {
            path: "/admin_www/module/ad",
            name: "admin_www_ad",
            component: () => import("./../adminViews/www/adManage.vue"),
            meta: {
              title: "广告管理",
              permission: "www_banner_edit"
            }
          }
        ]
      },

      // {
      //   path: '/admin_www/app_config',
      //   name: 'admin_www_app_config',
      //   component: () => import('./../adminViews/www/appConfig'),
      //   meta: {
      //     title: 'app更新管理',
      //     permission: 'admin_www_app_config'
      //   }
      // },

      {
        path: "/admin_www/game_manage",
        name: "admin_www_game_manage",
        component: () => import("./../adminViews/www/gameManage"),
        meta: {
          title: "游戏管理",
          permission: "www_game_manage"
        }
      },
      {
        path: "/admin_www/content_emoji_config",
        name: "admin_www_content_emoji_config",
        component: () => import("./../adminViews/www/contentEmojiConfig"),
        meta: {
          title: "文章表情包",
          permission: "www_game_manage"
        }
      },
      {
        path: "/admin_www/emoji_config",
        name: "admin_www_emoji_config",
        component: () => import("./../adminViews/www/emojiConfig"),
        meta: {
          title: "评论表情包",
          permission: "www_game_manage"
        }
      },
      {
        path: "/admin_www/emoji_config_group",
        name: "admin_www_emoji_config_group",
        component: () => import("./../adminViews/www/emojiConfigGroup"),
        meta: {
          title: "评论表情包分组",
          permission: "www_game_manage"
        }
      },
      {
        path: "/admin_www/game_alias_redirect",
        name: "admin_www_game_alias_redirect",
        component: () => import("./../adminViews/www/gameAliasRedirect"),
        meta: {
          title: "wiki重定向",
          permission: "www_game_alias_redirect"
        }
      },
      {
        path: "/admin_www/qyun_game_manage",
        name: "admin_www_qyun__game_manage",
        component: () => import("./../adminViews/www/qyunGameManage"),
        meta: {
          title: "去云吧游戏管理",
          permission: "www_qyun_game_manage"
        }
      },
      {
        path: "/admin_www/other_config",
        name: "admin_www_other_config",
        component: () => import("./../adminViews/www/otherConfig"),
        meta: {
          title: "其他配置",
          permission: "www_qyun_other_config"
        }
      }
    ]
  },
  {
    path: "/admin_www",
    name: "admin_www_user",
    component: adminWwwBase,
    redirect: "/admin_www/user_manage",
    meta: {
      title: "用户管理"
    },
    children: [
      {
        path: "/admin_www/user_manage",
        name: "admin_www_user_manage",
        component: () => import("./../adminViews/www/userManage"),
        meta: {
          title: "用户管理",
          permission: "www_user_manage"
        }
      },
      {
        path: "/admin_www/blocked_list",
        name: "admin_www_blocked_list",
        component: () => import("./../adminViews/www/blockedList"),
        meta: {
          title: "封禁管理",
          permission: "www_blocked_list"
        }
      },
      {
        path: "/admin_www/sys_msg",
        name: "admin_sys_msg",
        component: () => import("@/adminViews/www/sysMsg"),
        meta: {
          title: "消息推送",
          permission: "admin_www_sys_msg"
        }
      }
    ]
  },
  {
    path: "/admin_www",
    name: "admin_www_permission",
    component: adminWwwBase,
    redirect: "/admin_www/wiki_permission",
    meta: {
      title: "权限管理"
    },
    children: [
      {
        path: "/admin_www/wiki_permission",
        name: "admin_www_wiki_permission",
        component: () => import("@/adminViews/wikiPermission"),
        meta: {
          title: "角色列表",
          permission: "role_manage"
        }
      },
      {
        path: "/admin_www /wiki_permission_sync",
        name: "admin_www_wiki_permission_sync",
        component: () => import("@/adminViews/wikiPermissionSync"),
        meta: {
          title: "权限树",
          permission: "role_manage"
        }
      }
    ]
  }
];

export default adminWwwRouter;
