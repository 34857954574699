import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import Vue from "vue";
import tippy from "tippy.js";

import EmojiPanel from "./src/EmojiPanel.vue";

let tippyInstance = null;
let emojiPanelInstance = null;
let resolveFun = null;
let rejectFun = null;
let selectEmoji = null;

function initEmojiPanel(el, content) {
  if (tippyInstance) {
    tippyInstance.destroy();
  }

  tippyInstance = tippy(el, {
    content,
    interactive: true,
    trigger: "manual",
    placement: "bottom-start",
    appendTo: document.body,
    theme: "light",
    arrow: false,
    offset: [0, 5],
    showOnCreate: true,
    maxWidth: "none",
    onHide() {
      if (selectEmoji) resolveFun(selectEmoji);
      else rejectFun();
      selectEmoji = null;
    }
  });
}

function mountInstanceToDom() {
  if (emojiPanelInstance) {
    return emojiPanelInstance.$el;
  }

  const EmojiPanelClass = Vue.extend({
    render(h) {
      return h(EmojiPanel, {
        ref: "emojiPanel",
        on: {
          click: value => {
            selectEmoji = value;
            tippyInstance.hide();
          }
        }
      });
    }
  });
  const mountEl = document.createElement("div");
  document.body.appendChild(mountEl);
  const vm = new EmojiPanelClass();
  vm.$mount(mountEl);
  emojiPanelInstance = vm;
  return vm.$el;
}

/**
 * 打开表情面板
 * @param {HTMLElement} el - 触发表情面板的元素
 */
function openEmojiPanel(el) {
  // if (tippyInstance) {
  //   tippyInstance.show();
  //   return;
  // }
  return new Promise((resolve, reject) => {
    resolveFun = resolve;
    rejectFun = reject;
    const mountEl = mountInstanceToDom();
    initEmojiPanel(el, mountEl);
  });
}

export { openEmojiPanel };
