<template>
  <div>
    <wiki-header v-if="device.desktop" />
    <div v-if="!device.desktop && !device.inApp" class="app-bar">
      <div class="app-bar-content">
        <div class="back-btn" @click="$router.back()"><i class="el-icon-arrow-left" /></div>
        <div class="title">消息</div>
      </div>
    </div>
    <div :class="[classBase + 'body']" class="user-body">
      <div class="wiki-content">
        <div :class="[classBase + 'msg-main']" class="msg-main">
          <div class="nav-box">
            <div class="nav-bar">
              <router-link class="item" replace to="/msg/1" @click.native="clearRedPoint('zan')"
                >赞了我的
                <span v-if="redPoint.zan > 0" class="red-point">{{ redPoint.zan > 99 ? "99+" : redPoint.zan }}</span>
              </router-link>
              <router-link class="item" replace to="/msg/3" @click.native="clearRedPoint('replay')"
                >回复我的
                <span v-if="redPoint.replay > 0" class="red-point">{{
                  redPoint.replay > 99 ? "99+" : redPoint.replay
                }}</span></router-link
              >
              <router-link class="item" replace to="/msg/5" @click.native="clearRedPoint('system')"
                >系统消息
                <span v-if="redPoint.system > 0" class="red-point">{{
                  redPoint.system > 99 ? "99+" : redPoint.system
                }}</span></router-link
              >
            </div>
          </div>
          <div class="router-view">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WikiHeader from "@/layout/wiki/components/wikiHeader";
import { mapGetters } from "vuex";

export default {
  name: "wikiMsg",
  mixins: [],
  props: {},
  model: {},
  components: { WikiHeader },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["redPoint"])
  },
  methods: {
    onTip() {
      this.$message("系统暂未开发！");
    },
    clearRedPoint(type) {
      this.$store.commit("set_redPointType", {
        type,
        value: 0
      });
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.user-body {
  padding-top: 54px;
  .wiki-content {
    padding-top: 16px;
    width: 960px;
    margin: auto;
  }
}

.msg-main {
  display: flex;
}

.nav-box {
  width: 165px;
  background: #fff;
  margin-right: 16px;
  border-radius: 6px;
  padding: 0 20px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    color: #666;
    cursor: pointer;
    position: relative;

    .red-point {
      //position: absolute;
      //top: 0;
      //left: 90%;
      background: red;
      border-radius: 20px;
      height: 16px;
      display: flex;
      align-items: center;
      padding: 0 6px;
      color: #fff;
      font-size: 12px;
      margin-left: 4px;
    }

    &.router-link-active {
      color: #171717;
      font-weight: 500;
    }

    & + .item {
      border-top: 1px solid @border-basic;
    }
  }
}

.pc-msg-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .router-view {
    flex: 1;
    background: #fff;
    min-height: 500px;
    border-radius: 6px;
    padding: 28px;
    margin-bottom: 20px;
    overflow: hidden;
  }
}

.wap-body {
  padding-top: 0;

  .wiki-content {
    width: 100%;
    padding: 12px;
  }

  .router-view {
    width: 100%;
    padding: 12px;
  }
}

.wap-msg-main {
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;

  .nav-box {
    width: calc(100% - 24px);
    margin: auto;
    border-bottom: 1px solid @border-basic;
    padding: 0;
  }

  .nav-bar {
    display: flex;
    justify-content: space-around;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    padding: 0;

    .item {
      display: inline-flex;
      align-items: center;
      padding: 0 10px;
      color: #8b8b8b;
      border: none;
      position: relative;

      &:hover {
        color: #8b8b8b;
      }

      &.router-link-active {
        color: #000;
        font-weight: bold;
      }
    }
  }
}
</style>
