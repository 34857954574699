var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['home-header', _vm.hasScroll ? 'scroll-bg' : ''],style:(_vm.hasScroll || _vm.showHeader ? (_vm.theme ? { backgroundColor: _vm.theme.headerBg } : {}) : {})},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"left"},[_vm._m(0),(_vm.gameInfo.Config)?_c('router-link',{attrs:{"to":_vm.$route.name == 'wikiHomeNew' ? ("/" + _vm.alias + "/game") : ("/" + _vm.alias + "/")}},[_c('img',{staticClass:"game-slogn",attrs:{"src":_vm.gameInfo.Config.slogan,"alt":""}})]):_vm._e()],1),_c('div',{staticClass:"right"},[(_vm.scrollTop > 120 && _vm.$route.name == 'wikiHomeNew')?_c('div',{staticClass:"header-btns"},[_c('button',{staticClass:"btn",style:(_vm.theme
              ? {
                  backgroundColor: _vm.theme.headerBtnBg,
                  color: _vm.theme.headerBtnColor
                }
              : {}),on:{"click":_vm.goEdit}},[_c('i',{staticClass:"icon zsicon icon-bianji",attrs:{"alt":""}}),_c('span',[_vm._v("投稿/发帖")])]),_c('button',{staticClass:"btn",style:(_vm.theme
              ? {
                  backgroundColor: _vm.theme.headerBtnBg,
                  color: _vm.theme.headerBtnColor
                }
              : {}),on:{"click":_vm.openQuestion}},[_c('i',{staticClass:"icon zsicon icon-tiwen",attrs:{"alt":""}}),_c('span',[_vm._v(" 萌新提问 ")])])]):_vm._e(),_c('div',{staticClass:"search-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.kw),expression:"kw"}],attrs:{"type":"text","placeholder":"搜你所想"},domProps:{"value":(_vm.kw)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch($event)},"input":function($event){if($event.target.composing){ return; }_vm.kw=$event.target.value}}}),_c('i',{staticClass:"icon zsicon icon-sousuo",attrs:{"alt":""},on:{"click":_vm.onSearch}})]),(_vm.$route.name == 'wikiHomeNew')?_c('div',{staticClass:"wiki-nav"},[_c('el-dropdown',{attrs:{"trigger":"hover","placement":"bottom"},on:{"command":_vm.jumpNav}},[_c('div',{staticClass:"nav-box"},[_c('i',{staticClass:"zsicon icon-daohang"}),_c('span',[_vm._v("导航")])]),(_vm.entryList.length > 1)?_c('el-dropdown-menu',{staticClass:"nav-menu",attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.entryList),function(item){return _c('el-dropdown-item',{key:item.id,attrs:{"command":item.id}},[_vm._v(_vm._s(item.name))])}),1):_vm._e()],1)],1):_vm._e(),(_vm.loginUid)?_c('div',{staticClass:"user"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.loginUser.avatar,"alt":""}}),(_vm.redPoint.total > 0)?_c('span',{staticClass:"red-point"},[_vm._v(_vm._s(_vm.redPoint.total > 99 ? "99+" : _vm.redPoint.total))]):_vm._e(),_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":_vm.loginUser.avatar,"alt":""}})]),_c('div',{staticClass:"username"},[_vm._v(_vm._s(_vm.loginUser.username))]),_c('div',{staticClass:"btns"},[_c('router-link',{staticClass:"btn",style:(_vm.theme
                  ? {
                      backgroundColor: _vm.theme.primaryBtnBg,
                      color: _vm.theme.primaryBtnColor
                    }
                  : {}),attrs:{"to":("/user/index/" + (_vm.loginUser.uid) + ".html")}},[_vm._v("个人中心")]),_c('el-popover',{attrs:{"popper-class":"wxacode-popover","visible-arrow":false,"trigger":"hover","placement":"bottom-start","width":"218"},model:{value:(_vm.showWxacodePopover),callback:function ($$v) {_vm.showWxacodePopover=$$v},expression:"showWxacodePopover"}},[_c('template',{slot:"reference"},[_c('router-link',{staticClass:"btn",style:(_vm.theme
                      ? {
                          backgroundColor: _vm.theme.primaryBtnBg,
                          color: _vm.theme.primaryBtnColor
                        }
                      : {}),attrs:{"to":"/msg/1"}},[_vm._v(" 我的消息 "),(_vm.redPoint.total > 0)?_c('span',{staticClass:"red-point"},[_vm._v(_vm._s(_vm.redPoint.total > 99 ? "99+" : _vm.redPoint.total))]):_vm._e()])],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_c('div',[_vm._v("扫码关注")]),_c('div',[_vm._v("GameKee服务号")])]),_c('div',{staticClass:"tips"},[_c('div',[_vm._v("互动消息实时推送")])]),_c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":_vm.getServiceQrcodeSrc(),"alt":"服务号二维码"}})])])],2)],1),_c('div',{staticClass:"logout",on:{"click":_vm.loginOut}},[_vm._v("退出登录")])])]):_c('div',{staticClass:"user"},[_c('img',{staticClass:"not-login",attrs:{"src":require("@/assets/zs_imgs/wiki-icon-user.png"),"alt":""},on:{"click":_vm.onShowLogin}})])])]),_c('QuestionAdd',{ref:"questionAdd",attrs:{"customClass":"dark"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/logo1.png"),"alt":""}})])}]

export { render, staticRenderFns }